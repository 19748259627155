.main-footer{
    background-color:#004AAC;
    padding-top: 1%;
    color: #FFFCEF;
    /* margin-top: -200px; */
}

.hack{
    padding-top:5%;
}

.title{
    font-style: normal;
    font-weight: normal;
    font-size: 28.8295px;
    line-height: 51px;
}