.navbar {
  margin-bottom: 25px;
}

button.link-text {
  border: 0;
  padding: 0;
  color: #007bff;
  background: transparent;
}

.link-text {
  text-decoration: none !important;
  font-size: 25px;
  margin: 25px;
}
