.volunteer-time {
  text-align: right;
  width: 100%;
  overflow-x: hidden;
}

#volunteer-delete {
  float: right;
  margin: 0px 10px;
}

#volunteer-edit {
  float: right;
}

#volunteer-sign-up {
  float: right;
  margin: 0px 10px;
}

#volunteer-sign-up button {
  background-color: rgb(96, 211, 96);
  color: white;
  text-decoration: none !important;
  border-radius: 18px;
}

#volunteer-sign-up button:hover {
  background-color: rgb(51, 151, 51);
}

#volunteer-sign-up button:disabled {
  background-color: rgba(0, 0, 0, 0.1);
}

#confirm-delete {
  margin-right: 15px;
}

h6 {
  font-size: 1.2rem;
}

.signup-status {
  font-style: italic;
  font-weight: bold;
}
