@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&family=Raleway:wght@300;400;500&display=swap');

.volunteerContainer{
    margin: 3%;
    border-style: solid;
    border-radius: 46px;
    border-color: #004AAC;
    background-color: #FFFCEF;
    padding: 30px;
    box-shadow: -10px 10px 5px #F3D352;
}

.scroll{
    height: 70vh;
    overflow-y: scroll;
    margin-left: 30px;
    box-shadow: 0 15px 10px -10px #004AAC;
}

.hours{
    font-size: 20px;
}

.title{
    margin-left: 5%;
}

.ApprovalSection{
    float: right;
    padding: 1%;
    margin-bottom: 1%;
    border-style: solid;
    border-radius: 5%;
    border-color: #004AAC;
    background-color: #FFFCEF;
    width: 85%;
    overflow-y: scroll;
    height: 200px;

}

.personRow{
    display: flex;
    justify-content: space-between;
}

.thumbs{
    margin-top: -12%;
}
