.event-month-view {
  text-align: center;
  border-radius: 10px;
  width: 90%;
  margin: 5px auto;
  color: white;
  background-color: var(--font-color);
  text-overflow: ellipsis;
  overflow: hidden;
  padding: 0 2px;
}

.rbc-event {
  background: none !important;
}
