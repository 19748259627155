 .create {
    display: flex;
    flex-direction: column;
    align-content: space-around;
    padding: 5%;
}

.form {
    display: flex;
    flex-direction: column;
}

.MakeAnnouncementBlock{
    margin: 5%;
    border-style: solid;
    border-radius: 5%;
    border-color: #004AAC;
    background-color: #FFFCEF;
    box-shadow: -10px 10px 5px #F3D352;
}

.MakeAnnouncementTitle{
    width:50%;
    text-align: center;
    color: #004AAC;
    padding-left: 5%;
    padding-top: 4%;
    text-decoration: none !important;
    font-size: 48 rhm;
}

.AnnouncementTitle{
    color: #004AAC;
    padding-left: 5%;
    padding-bottom: 3%;
    /* font-family: 'Permanent Marker', cursive; */
    text-decoration: none !important;
    font-size: 72 rhm;
}

.formLabel {
    color: #004AAC;
    padding-top: 4%;
    padding-bottom: 0px;
    font-size: 24px;
    text-decoration: none !important;
}

.submitButton{
    background-color: #004AAC;
    color:white;
    text-decoration: none !important;
    font-size: 20px;
    margin-top: 10px;
    border-radius: 46px;
}

.createButton{
    background-color: #004AAC;
    color:white;
    text-decoration: none !important;
    font-size: 20px;
    border-radius: 46px;
    margin-left: 5%;
    margin-bottom: 10%;
    padding:12px;
}

.submitButton:focus{
    outline: none;
}

.createButton:hover{
    color:white;
}

.contentBox{
    font-size: 20px;
    color: #64aeff;
    padding: 20px;
    padding-top:10px;
    border-radius: 46px;
}

.contentBox:focus{
    outline: none;
}

.create{
   padding-top: 0px;
}

.Announcement{
    margin: 5%;
    border-style: solid;
    border-radius: 46px;
    border-color: #004AAC;
    background-color: #FFFCEF;
    padding: 30px;
    box-shadow: -10px 10px 5px #F3D352;
}

.top{
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    color:  #004AAC;
    align-items: center;
}

.Title{
    text-align: left;
    font-size: 24px;
}

.Author{
    text-align: right;
    font-size: 22px;
}

.Content{
    color: #3c86e8;
    font-size: 18px;
}

.Date{
    color: #3c86e8;
    font-size: 18px;
    text-align: right;
}

.return {
    border-style: solid;
    border-radius: 46px;
    color: #004AAC;
    background-color: #FFFCEF;
    margin-left: 300%;
}

.returnIcon:hover {
    background-color: inherit !important;
}

.right {
    display: flex;
    align-items: center;
}

h1 {
  margin-bottom: 0;
}
